import styled from "styled-components";

const Jumbotron = styled.img`
    width: 100vw;
    margin: 0;
    paddingtop: 60px;
    z-index: -1;
`;

export default Jumbotron;
